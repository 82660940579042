@import '~antd/dist/antd.css';

.App {
  text-align: center;
}



.site-layout-content {
  min-height: 280px;
  padding: 1vh 10vw;
  
  
  border-color: black;
}
.logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.3);
}
.ant-row-rtl .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

.main-title {
  font-size: 40px;
  color: #fff;
  margin: 0px;
}



.main-content-font {
  font-size: 18px;
  color: #1d1d1d;
  font-weight: 400;
  padding-left: 10px;
  
}

.page-content {
  background: linear-gradient(rgba(40, 62, 82, 0.4), rgba(15, 15, 15, 0.8)) , 
   url(./image/home-background.gif) fixed;   
   min-height: calc(100vh - 64px)
}


.desktop-navbar {
  display: flex;
  justify-content: center;
}

.mobile-navbar{
  display: none;
}

.project-modal {
  
  max-width: 900px;
  
}

@media (max-width:720px) {
  .desktop-navbar {
    display: none;
    font-size:50px;
  }
  .mobile-navbar{
    display: flex;
    align-items: center;
  }
  .site-layout-content{
    padding: 1vh 3vw;
  }
  .project-modal {
    min-width: 97vw;
  }
}

