.combined {
    color: #fff;
    width: 480px;
    height: 120px;
    position: absolute;
    top: -20px;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
  }
  .combined-shape {
    position: relative;
  }
  .combined-shape > * {
    width: 50%;
    height: 60px;
    position: absolute;
    overflow: hidden;
  }
  .combined-shape > * > * {
    width: 100%;
    height: 100%;
    background: #fff;
  }
  .combined-shape .shape-left {
    left: 0;
  }
  .combined-shape .shape-right {
    right: 0;
  }
  .combined .title {
    font-size: 48px;
    letter-spacing: 8px;
    position: absolute;
    width: 470px;
    overflow: hidden;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    text-align: center
  }
  .combined-bar {
    width: 100%;
    height: 2px;
    background: #fff;
    position: absolute;
    top: 70px;
  }
  .combined .content {
    font-size: 18px;
    text-align: center;
    position: absolute;
    width: 100%;
    top: 78px;
    text-align: center

  }

  @media (max-width:480px) {
    .combined{
        width: 90vw;
    }
    .combined-shape{
        display: none;
    }
    .combined-bar {
        top: 14vw;
    }
    .combined .title {
        font-size: 9vw;
    }
    .combined .content {
        top: 16vw;
    }
  }